import * as React from "react"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { graphql } from "gatsby"
import Layout from "../../components/layout"
import Seo from "../../components/irkutsk"
const IndexPage = ({ data }) => {
  return (
  <Layout>
    <div id="wrapper">
		<div id="main">
			<div class="inner">
				<div id="image01" class="image" data-position="center"><span class="frame">
        <GatsbyImage image={data.file.childImageSharp.gatsbyImageData} alt="Vipdosug Иркутск"/></span></div>
     <h1 id="text04">Проститутки Иркутска на VipDosug</h1>
     <h2 id="text03">Проведите отлично свое время с индивидуалками Иркутска!</h2>
				<ul id="buttons02" class="buttons">
					<li><a href="/cgi-bin/vip.pl" class="button n01">Вход на портал 18+</a></li>
				</ul>
        <ul id="buttons05" class="buttons">
					<li><Link to="/" class="button n01">Другой город</Link></li>
				</ul>
				<p id="text02">Чем вам известен город Иркутск? Да, здесь сильные морозы и именно здесь был арестован лидер белого движения Колчак. Ну, помимо этих фактов, еще что это очень красивый город в котором можно просто отлично провести время. Здесь можно даже воплотить в жизнь самые откровенные эротические фантазии. Потому что <em>индивидуалки города Иркутска</em> уже истосковались по мужчинам, которые бы хотели отлично провести свое время. В перечне интимных услуг проституток Иркутска вы можете найти то, что вам идеально подходит, то, чего бы вы давно и сильно хотели. Экзотического анального секса или обычного вагинального? Может быть вы давно хотели увидеть, как женщина танцует для вас приватный танец или стриптиз. Может хотели почувствовать ее нежные губки на своем достоинстве. Теперь вы можете себе это позволить ведь <strong>проститутки Иркутска</strong> ждут вашего звонка. Хватит мечтать о сексе - пора им заняться! Звоните по номеру ... и вскоре ваши самые сокровенные мысли станут фантастической реальностью!</p>               
				<ul id="icons02" class="icons">
					<li>
						<a class="n01" href="https://ru.pinterest.com/dosugru/" aria-label="Pinterest">
            <svg viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
            <title id="icon-title">Pinterest</title>
                      <path d="M35.3,20c0,2.8-0.7,5.4-2.1,7.7c-1.4,2.4-3.3,4.2-5.6,5.6c-2.4,1.4-4.9,2.1-7.7,2.1c-1.5,0-2.9-0.2-4.4-0.6 c0.8-1.2,1.3-2.3,1.6-3.3c0.1-0.5,0.5-1.9,1.1-4.2c0.3,0.5,0.8,1,1.5,1.4c0.7,0.4,1.5,0.6,2.3,0.6c1.6,0,3.1-0.5,4.3-1.4 c1.3-0.9,2.3-2.2,3-3.8c0.7-1.6,1-3.4,1-5.4c0-1.5-0.4-3-1.2-4.3c-0.8-1.3-2-2.4-3.5-3.3c-1.5-0.8-3.2-1.3-5.1-1.3 c-1.4,0-2.7,0.2-3.9,0.6c-1.2,0.4-2.3,0.9-3.1,1.5c-0.9,0.6-1.6,1.4-2.2,2.2c-0.6,0.8-1.1,1.7-1.3,2.6c-0.3,0.9-0.4,1.8-0.4,2.7 c0,1.4,0.3,2.6,0.8,3.7c0.5,1.1,1.3,1.8,2.3,2.2c0.4,0.2,0.7,0,0.8-0.4c0-0.1,0.1-0.3,0.2-0.6c0.1-0.3,0.1-0.5,0.2-0.6 c0.1-0.3,0-0.6-0.2-0.9c-0.7-0.8-1-1.8-1-3c0-2,0.7-3.8,2.1-5.2c1.4-1.5,3.2-2.2,5.5-2.2c2,0,3.6,0.5,4.7,1.6 c1.1,1.1,1.7,2.5,1.7,4.3c0,2.3-0.5,4.2-1.4,5.8s-2.1,2.4-3.5,2.4c-0.8,0-1.5-0.3-2-0.9c-0.5-0.6-0.6-1.3-0.5-2.1 c0.1-0.5,0.3-1.1,0.5-1.9c0.2-0.8,0.4-1.5,0.6-2.1c0.2-0.6,0.2-1.1,0.2-1.5c0-0.7-0.2-1.2-0.5-1.7c-0.4-0.4-0.9-0.7-1.5-0.7 c-0.8,0-1.5,0.4-2.1,1.1c-0.6,0.8-0.9,1.7-0.9,2.9c0,1,0.2,1.8,0.5,2.5l-2,8.4c-0.2,0.9-0.3,2.1-0.3,3.6c-2.8-1.2-5-3.1-6.7-5.6 S4.4,23.1,4.4,20c0-2.8,0.7-5.4,2.1-7.7S9.7,8,12.1,6.6s4.9-2.1,7.7-2.1c2.8,0,5.4,0.7,7.7,2.1s4.2,3.3,5.6,5.6 C34.6,14.6,35.3,17.2,35.3,20L35.3,20z" fill="#000000"></path>
                    </svg><span class="label">Pinterest</span></a>
					</li>
					<li>
						<a class="n02" href="https://twitter.com/DosugczOfficial" aria-label="X">
            <svg viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
            <title id="icon-title">Twitter</title>
                      <path d="M30.1,4h5.4L23.7,17.6L37.6,36H26.7l-8.5-11.2L8.5,36H3.1l12.6-14.5L2.4,4h11.1l7.7,10.2L30.1,4z M28.2,32.8h3L11.9,7.1H8.7 L28.2,32.8z" fill="#000000"></path>
                    </svg><span class="label">X</span></a>
					</li>
				</ul>
			</div>
		</div>
	</div>
  </Layout>
)
}
export const Head = () =><Seo title="Проститутки Иркутска, лучшие индивидуалки | VipDosug.org"/>

export default IndexPage

export const query = graphql`
query MyQuery {
  file(relativePath: {eq: "irkutsk.png"}) {
    childImageSharp {
      gatsbyImageData(
        height: 182
        width: 182
        formats: [AUTO, PNG, AVIF, WEBP]
        layout: CONSTRAINED
        quality: 100
        pngOptions: {quality: 100}
        avifOptions: {quality: 100}
        webpOptions: {quality: 100}
        breakpoints: [18, 36, 54, 72, 91, 109, 127, 145, 163, 182]
        placeholder: NONE
      )
    }
  }
}
`